import React, { useState } from 'react';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import SearchForm from './searchForm';
import SearchResultTable from './search_result_table';
import { partitionByVillageFatherMatch } from '../../helpers/searchUtils';

const SearchModal = ({ onClose, gender, onSelectUser, handleNoneOfTheseClick }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [village, setVillage] = useState('');

  const handleResults = (sendResults, searchParams) => {
    // const sortedResults = partitionByVillageFatherMatch(
    //   sendResults?.search_results,
    //   searchParams,
    //   sendResults?.input_village_details,
    // );
    // setSearchResults(sortedResults);
    setSearchResults(sendResults?.search_results);
    setSearchParams(searchParams);
    setVillage(sendResults?.input_village_details);
    setShowSearchResult(true);
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          {showSearchResult ? (
            <SearchResultTable
              searchResults={searchResults}
              setSelectedUser={onSelectUser}
              onClose={onClose}
              noneOfThese={true}
              selectButton={true}
              searchParams={searchParams}
              village={village}
              onNoneOfTheseClick={handleNoneOfTheseClick}
            />
          ) : (
            <SearchForm
              sendResults={handleResults}
              fields={['name', 'father', 'gautra', 'village']}
              gender_value={gender}
              heading="Search for user"
              buttonText="Search"
            />
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default SearchModal;
