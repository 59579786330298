import { useCallback } from 'react';

const createActionData = (action, loggedInUser) => {
  const getActionData = useCallback(
    (action) => ({
      action_at: new Date(),
      action: action,
      actor: {
        name: loggedInUser?.name,
        gautra: loggedInUser?.gautra,
        subcaste: loggedInUser?.subcaste,
        village: loggedInUser?.village,
        pic_url: loggedInUser?.pic_url || null,
      },
    }),
    [loggedInUser],
  );

  return getActionData;
};

export default createActionData;
